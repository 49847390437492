import { translation, TranslatedComponent } from '@hh.ru/front-static-app';
import { Button } from '@hh.ru/magritte-ui';

interface CardCourseSubscribeButtonProps {
    onClick: () => void;
}

const TrlKeys = {
    subscribe: 'career-platform.subscribe-to-course.subscribe',
};
const CardCourseSubscribeButton: TranslatedComponent<CardCourseSubscribeButtonProps> = ({ onClick, trls }) => (
    <Button style="neutral" mode="primary" size="small" onClick={onClick} stretched>
        {trls[TrlKeys.subscribe]}
    </Button>
);

export default translation(CardCourseSubscribeButton);
