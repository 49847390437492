import { translation, format, TranslatedComponent } from '@hh.ru/front-static-app';
import { Text } from '@hh.ru/magritte-ui';

import { Currency } from 'src/types/common';
import { getCurrency, getCurrencyArray } from 'src/utils/currency';

import styles from './styles.less';

const TrlKeys = {
    primary: 'career-platform.components.price.primary',
    secondary: 'career-platform.components.price.secondary',
    free: 'career-platform.components.price.free',
};

interface CardCoursePriceProps {
    fullAmount: number | null;
    amountPerMonth?: number | null;
    discountAmount?: number | null;
    currency: `${Currency}`;
}

/** @deprecated after exp HH-238596 */
const CardCoursePrice: TranslatedComponent<CardCoursePriceProps> = ({
    trls,
    amountPerMonth,
    fullAmount,
    discountAmount,
    currency,
}) => {
    const isFree = !fullAmount && !amountPerMonth && !discountAmount;
    const isOnlyFullAmount = typeof fullAmount === 'number' && !amountPerMonth && !discountAmount;
    const isShowFullPriceWithPerMonth = !discountAmount && !!amountPerMonth && !!fullAmount;
    const isShowFullPriceWithDiscount = !amountPerMonth && !!fullAmount && !!discountAmount;

    if (isFree) {
        return <p className={styles.primary}>{trls[TrlKeys.free]}</p>;
    }

    const renderBigAmount = (fullAmount: number, currency: `${Currency}`) => {
        const [amountString, currencyString] = getCurrencyArray(fullAmount, currency);
        return (
            <div className={styles.primary}>
                {amountString}
                <Text typography="subtitle-3-semibold">{currencyString}</Text>
            </div>
        );
    };

    const renderMonthAmount = (monthAmount: number, currency: `${Currency}`) => {
        const [amountString, currencyString] = getCurrencyArray(monthAmount, currency);
        return (
            <div className={styles.primary}>
                {amountString}
                <Text typography="subtitle-3-semibold">{format(trls[TrlKeys.primary], { '{0}': currencyString })}</Text>
            </div>
        );
    };

    const renderSecondary = (fullAmount: number | null, discountAmount: number, currency: `${Currency}`) => {
        return (
            <div className={styles.secondary}>
                <Text typography="label-4-regular">
                    {format(trls[TrlKeys.secondary], { '{0}': getCurrency(discountAmount, currency) })}
                </Text>
                {fullAmount && (
                    <Text typography="label-5-regular" style="secondary">
                        <s>{getCurrencyArray(fullAmount, currency)[0]}</s> {getCurrencyArray(fullAmount, currency)[1]}
                    </Text>
                )}
            </div>
        );
    };

    return (
        <div className={styles.container}>
            {isOnlyFullAmount && renderBigAmount(fullAmount, currency)}
            {!!amountPerMonth && renderMonthAmount(amountPerMonth, currency)}
            {isShowFullPriceWithPerMonth && (
                <Text Element="p" typography="label-4-regular">
                    {format(trls[TrlKeys.secondary], { '{0}': getCurrency(fullAmount, currency) })}
                </Text>
            )}
            {!!discountAmount && !!amountPerMonth && renderSecondary(fullAmount, discountAmount, currency)}
            {isShowFullPriceWithDiscount && (
                <div className={styles.secondary}>
                    {renderBigAmount(discountAmount, currency)}
                    {fullAmount && (
                        <Text typography="label-5-regular" style="secondary">
                            <s>{getCurrencyArray(fullAmount, currency)[0]}</s>
                            {getCurrencyArray(fullAmount, currency)[1]}
                        </Text>
                    )}
                </div>
            )}
        </div>
    );
};

/** @deprecated after exp HH-238596 */
export default translation(CardCoursePrice);
