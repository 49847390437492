import { translation, TranslatedComponent } from '@hh.ru/front-static-app';
import { Button } from '@hh.ru/magritte-ui';

interface CardCourseRequestButtonProps {
    onClick: () => void;
}

const TrlKeys = {
    leaveRequest: 'career-platform.components.card-course.request-to-course.button.leaveRequest',
};

const CardCourseRequestButton: TranslatedComponent<CardCourseRequestButtonProps> = ({ onClick, trls }) => (
    <Button mode="primary" size="small" onClick={onClick} stretched>
        {trls[TrlKeys.leaveRequest]}
    </Button>
);

export default translation(CardCourseRequestButton);
