import React from 'react';

import { translation, format, TranslatedComponent } from '@hh.ru/front-static-app';
import { GridColumn, GridLayout, GridRow, Text } from '@hh.ru/magritte-ui';

import { EMAIL_SUPPORT } from 'src/modules/emails';
import { analytics, Goal } from 'src/utils/analytics';

import FooterNavGroup from './FooterNavGroup';
import FooterNavLink from './FooterNavLink';
import FooterNavList from './FooterNavList';
import FooterNavTitle from './FooterNavTitle';

import styles from './styles.less';

const TrlKeys = {
    about: {
        title: 'career-platform.footer.about.title',
        termsOfUse: 'career-platform.footer.about.termsOfUse',
        forMassMedia: 'career-platform.footer.about.forMassMedia',
    },
    partners: {
        title: 'career-platform.footer.partners.title',
        becomePartner: 'career-platform.footer.partners.becomePartner',
        becomeExpert: 'career-platform.footer.partners.becomeExpert',
    },
    help: {
        title: 'career-platform.footer.help.title',
        email: 'career-platform.footer.help.email',
    },
    recommendationTechnologies: 'career-platform.footer.recommendationTechnologies',
    dataInfo: 'career-platform.footer.dataInfo',
    copyright: 'career-platform.footer.copyright',
};

const LinkKeys = {
    termsOfUse: 'https://hh.ru/article/careerru_user_agreement',
    forMassMedia: 'https://hh.ru/article/28?utm_source=career.ru&utm_medium=referral&utm_campaign=footer',
    becomePartner: 'https://kakdela.hh.ru/poll/xWzT4CuwTRo7WgFsWdX3dWJk',
    becomeExpert: 'https://kakdela.hh.ru/poll/Nd6YwsD9yh0NIwttlz2?from=career.ru',
    recommendationTechnologies: 'https://hh.ru/article/recommendation_technologies?hhtmFrom=article',
};

const handleReachGoalBecomePartner = () => analytics.reach(Goal.click_footer_become_partner);
const handleReachGoalBecomeCareerExpert = () => analytics.reach(Goal.click_footer_become_career_expert);

const Footer: TranslatedComponent = ({ trls }) => (
    <footer className={styles.footer}>
        <GridLayout>
            <GridRow>
                <GridColumn xs={4} s={8} m={4}>
                    <FooterNavGroup>
                        <FooterNavTitle text={trls[TrlKeys.about.title]} />
                        <FooterNavList>
                            <FooterNavLink
                                text={trls[TrlKeys.about.termsOfUse]}
                                href={LinkKeys.termsOfUse}
                                isExternal
                            />
                            <FooterNavLink
                                text={trls[TrlKeys.about.forMassMedia]}
                                href={LinkKeys.forMassMedia}
                                isExternal
                            />
                        </FooterNavList>
                    </FooterNavGroup>
                </GridColumn>
                <GridColumn xs={4} s={8} m={4}>
                    <FooterNavGroup>
                        <FooterNavTitle text={trls[TrlKeys.partners.title]} />
                        <FooterNavList>
                            <FooterNavLink
                                text={trls[TrlKeys.partners.becomePartner]}
                                href={LinkKeys.becomePartner}
                                onClick={handleReachGoalBecomePartner}
                                isExternal
                            />
                            <FooterNavLink
                                text={trls[TrlKeys.partners.becomeExpert]}
                                href={LinkKeys.becomeExpert}
                                onClick={handleReachGoalBecomeCareerExpert}
                                isExternal
                            />
                        </FooterNavList>
                    </FooterNavGroup>
                </GridColumn>
                <GridColumn xs={4} s={8} m={4}>
                    <FooterNavGroup>
                        <FooterNavTitle text={trls[TrlKeys.help.title]} />
                        <FooterNavList>
                            <FooterNavLink
                                text={format(trls[TrlKeys.help.email], {
                                    '{0}': EMAIL_SUPPORT,
                                })}
                                href={`mailto:${EMAIL_SUPPORT}`}
                            />
                        </FooterNavList>
                    </FooterNavGroup>
                </GridColumn>
            </GridRow>
            <div className={styles.footerRecommendationTechnologies}>
                <FooterNavLink
                    text={trls[TrlKeys.recommendationTechnologies]}
                    href={LinkKeys.recommendationTechnologies}
                    isExternal
                    Element="div"
                />
            </div>
            <div className={styles.footerDataInfo}>
                <Text typography="paragraph-2-regular" style="secondary" Element="p">
                    {trls[TrlKeys.dataInfo]}
                </Text>
            </div>
            <div className={styles.footerCopyright}>
                <Text typography="paragraph-2-regular" style="secondary" Element="p">
                    {format(trls[TrlKeys.copyright], {
                        '{year}': new Date().getFullYear(),
                    })}
                </Text>
            </div>
        </GridLayout>
    </footer>
);

export default translation(Footer);
