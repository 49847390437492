import cardCourseButtonClick from '@hh.ru/analytics-js-events/build/career_platform/courses/card_course_button_click';
import { translation, TranslatedComponent, useExperiment, useSelector } from '@hh.ru/front-static-app';
import { Button } from '@hh.ru/magritte-ui';

import useFeatureEnabled from 'src/hooks/useFeatureEnabled';
import { CourseTypeFollowLink } from 'src/types/courses';
import { analytics, Goal } from 'src/utils/analytics';
import openNewBlankTab from 'src/utils/openNewBlankTab';

import { patchedUtmContentLink } from './patchedUtmContentLink';

const TrlKeys = {
    button: 'career-platform.components.button-link-external-course.button',
};

const Features = {
    newPriceUtmContentPatchEnable: 'new_price_utm_content_patch_enable',
    newPriceUtmContentPatchConfig: 'new_price_utm_content_patch_config',
    newPriceEnable: 'new_price_enable',
};

interface CardCourseButtonLinkExternalProps {
    course: CourseTypeFollowLink;
}

export interface PatchUtmContentConfig {
    providerIds: string[];
    appendValue: string;
}

export const cardCourseButtonLinkExternalHandler = (
    course: CourseTypeFollowLink,
    // tempexp_HH-238597_start
    canPatchUtmContent: boolean,
    patchUtmContentConfig?: PatchUtmContentConfig
    // tempexp_HH-238597_end
): void => {
    let link = course.link;

    // tempexp_HH-238597_start
    if (
        canPatchUtmContent &&
        patchUtmContentConfig &&
        patchUtmContentConfig.providerIds.includes(String(course.provider?.id))
    ) {
        link = patchedUtmContentLink(link, patchUtmContentConfig.appendValue);
    }
    // tempexp_HH-238597_end

    // так обходим блокировку контента со стороны adBlock,
    // abBlock большинство ссылок от партнеров считает рекламными и скрывает за display: none
    openNewBlankTab(link);
    analytics.reach(Goal.click_profession_guide_go_to_course);
    cardCourseButtonClick({
        courseName: course.name,
        streamName: course?.stream?.name,
        providerId: course?.provider?.id || null,
        providerName: course?.provider?.name || null,
        cardType: course.purpose,
        professionId: course.profession?.id || null,
        coursePrice: course.price?.fullAmount || course.price?.amountPerMonth || 0,
        priceDetails: JSON.stringify(course.priceDetails),
        courseLink: link,
    });
};

// tempexp_HH-238597_start
export const usePatchUtmContent = (): {
    canPatchUtmContent: boolean;
    patchUtmContentConfig: PatchUtmContentConfig;
} => {
    const isUtmContentPatchEnabled = useFeatureEnabled(Features.newPriceUtmContentPatchEnable);
    const newPriceEnable = useFeatureEnabled(Features.newPriceEnable);
    const isExp = useExperiment('cp_new_price', newPriceEnable);
    const patchUtmContentConfig = useSelector(
        (state) => JSON.parse(state.features[Features.newPriceUtmContentPatchConfig] as string) as PatchUtmContentConfig
    );

    const canPatchUtmContent = isExp && isUtmContentPatchEnabled;
    return { canPatchUtmContent, patchUtmContentConfig };
};
// tempexp_HH-238597_end

const CardCourseButtonLinkExternal: TranslatedComponent<CardCourseButtonLinkExternalProps> = ({ course, trls }) => {
    // tempexp_HH-238597_next-line
    const { canPatchUtmContent, patchUtmContentConfig } = usePatchUtmContent();

    return (
        <Button
            style="neutral"
            mode="primary"
            size="small"
            stretched
            onClick={() => cardCourseButtonLinkExternalHandler(course, canPatchUtmContent, patchUtmContentConfig)}
        >
            {trls[TrlKeys.button]}
        </Button>
    );
};

export default translation(CardCourseButtonLinkExternal);
