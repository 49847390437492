import { urlParser } from '@hh.ru/browser-api-utils';

export const patchedUtmContentLink = (link: string, appendValue: string): string => {
    const parsed = urlParser(link);

    let utmContent: string[] = [];
    if (Array.isArray(parsed.params?.utm_content)) {
        utmContent = parsed.params.utm_content as string[];
    }
    if (typeof parsed.params?.utm_content === 'string') {
        utmContent = parsed.params?.utm_content.split('.');
    }

    parsed.search = urlParser.stringify({
        ...parsed.params,
        // eslint-disable-next-line camelcase
        utm_content: [...utmContent, appendValue].filter(Boolean).join('.'),
    });
    return parsed.href;
};
